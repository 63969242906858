export const state = {
  isStageview: false
}

export const mutations = {
  SET_IS_STAGEVIEW(state, value) {
    state.isStageview = value
  }
}

export const actions = {
  setIsStageview({ commit }, value) {
    commit('SET_IS_STAGEVIEW', value)
  }
}
